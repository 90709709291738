import { Log } from 'ng2-logger/browser';
import { map, catchError } from 'rxjs/operators';
import { Email } from '../../interfaces/email';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { User } from '../../interfaces/user';
import { JwtToken } from 'app_code/app/shared/interfaces/jwt-token';

@Injectable()
export class RegistrationService implements OnInit {

    private log = Log.create('RegistrationService');

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    ngOnInit() {
        this.authService.redirectLoggedInUser();
    }

    public register(user: User, captcha: string): Observable<JwtToken> {

        let params = new HttpParams().set("captcha", captcha).set("action", "register");

        return this.http.post('/rest/register', user, {params: params})
            .pipe(
                map((res : JwtToken) => {return res;}),
                catchError((e: any) => {
                    if (e.status == 401) {
                        return throwError("Incorrect registration key.");
                    } else if (e.status == 409) {
                        return throwError("Email is already in use. Please log in or reset you password.");
                    } else {
                        return throwError("Something went wrong, please try again later.");
                    }
                }));
    }

    public checkIfUsernameIsAvailable(username: string, captcha: string): Observable<any> {

        let params = new HttpParams().set("captcha", captcha).set("action", "username");

        return this.http.get("rest/user/check/username/" + username, {params: params})
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    public activateAccount(id: string, captcha: string): Observable<any> {

        let params = new HttpParams().set("captcha", captcha).set("action", "register");

        return this.http.get('rest/register/activate' + id, {params: params})
            .pipe(
                map(res => {
                    return "ok";
                }),
                catchError(this.handleError))
    }

    public activateEmail(id: string): Observable<any> {
        return this.http.put('rest/user/email/' + id, null)
            .pipe(
                map(res => {
                    return "ok";
                }),
                catchError(this.handleError))
    }

    public requestPasswordReset(email: Email, captcha: string): Observable<any> {

        let params = new HttpParams().set("captcha", captcha).set("action", "register");

        return this.http.post('rest/login/reset/initiate', email, {params: params})
            .pipe(
                map(res => {

                }),
                catchError(this.handleError))
    }

    public resetPassword(key: string, password: string, captcha: string): Observable<any> {

        let params = new HttpParams().set("captcha", captcha).set("action", "register");

        return this.http.post('rest/login/reset', {key, password}, {params: params})
            .pipe(
                map(res => {

                }),
                catchError(this.handleError))
    }

    public resendRegistrationEmail(email: string, captcha: string): Observable<void> {

        let params = new HttpParams().set("captcha", captcha).set("action", "register");

        let emailObj: any = {
            email: email
        }
        return this.http.put('rest/register/resend/email', emailObj, {params: params})
            .pipe(
                map(res => {

                }),
                catchError(this.handleError))
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        if (errMsg === '409 - OK') {
            errMsg = 'Username/email already exists';
        }
        if (errMsg === '400 - OK') {
            errMsg = 'Invalid username';
        }
        this.log.data(errMsg);
        return throwError(errMsg);
    }
}
