
<div class="point-on-map" (click)="onPointClick()" (wheel)="onWheel($event)">
  <!-- <img *ngIf="!point.poi.images || point.poi.images.length === 0" class="point-on-map__image" [src]="image_path + point.poi.image"> -->
  <img *ngIf="point.poi.images && point.poi.images.length > 0" class="point-on-map__image" [src]="image_path + point.poi.images[0].image">

  <div *ngIf="point.poi.likes > 0" class="point-on-map__likes" [ngClass]="{'point-on-map__likes--bottom': !point.poi.title}">
    <shout-svg-icons [name]="'markerFilledHearth'" ></shout-svg-icons>
    <div class="point-on-map__likes__number">{{point.poi.likes > 99 ? '99+' : point.poi.likes}}</div>
  </div>

  <div class="point-on-map__title">{{point.poi.title}}</div>

  <div *ngIf="!isCollectionHintVisible && (searchType !== SearchType.COLLECTION || !hasSelectedItem)" class="point-on-map__collection-count">
    <span>{{point.numberOfPois > 99 ? '99+' : point.numberOfPois}}</span>
  </div>

  <div class="point-on-map__pointer">
    <div class="point-on-map__pointer__element">
      <shout-svg-icons [name]="'mapPointRectangle'"></shout-svg-icons>
    </div>
  </div>
</div>



