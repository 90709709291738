import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SearchType } from 'app_code/app/shared/interfaces/search-type.enum';
import { AuthService } from 'app_code/app/shared/services/auth/auth.service';
import { PointContentDTO } from 'app_code/app/shared/model/point-dto';
import { MODAL_COMPONENTS_NAME } from 'app_code/app/ui/modals/services/modal-components-list';
import { ModalsService } from 'app_code/app/ui/modals/services/modals.service';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { MapService } from '../../services/map.service';
import { ANDROID_ID_URL, APPLE_ID_URL, SMALL_IMAGE_PATH } from 'app_code/app/shared/constants';

@Component({
  selector: 'shout-point-on-map-marker',
  templateUrl: './point-on-map-marker.component.html',
  styleUrls: ['./point-on-map-marker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointOnMapMarkerComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() point: PointContentDTO;
  @Input() searchType: SearchType;
  @Input() hasSelectedItem: boolean;
  @Input() isWelcome = false;

  @Output() pointClicked = new EventEmitter<PointContentDTO>();
  @Output() ready = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  readonly IMAGE_PATH = SMALL_IMAGE_PATH;
  readonly APPLE_ID_URL = APPLE_ID_URL;
  readonly ANDROID_ID_URL = ANDROID_ID_URL;

  SearchType = SearchType;
  description: string;

  private destroy$ = new Subject<void>();
  private mouseZoom$ = new Subject<WheelEvent>();

  constructor(
    private modalService: ModalsService,
    private authService: AuthService,
    public mapService: MapService,
    public cd: ChangeDetectorRef
  ) { }

  @HostListener('document:click', ['$event'])
  public onClick(ev): void {
    if (ev.target.id === 'map-wrapper' && this.isWelcome) { // close dropdown in case of map click 
      this.close.emit();
    }
  }

  @HostListener('document:keydown.enter')
  public onEnterClick(): void {
   this.onSignUp();
  }

  @HostListener('window:resize')
  onResize() {
    if (this.isWelcome) {
      this.close.emit(); // close dropdown in screen resize
    }
  }

  get isLoggedIn(): boolean {
    return this.authService.isAccountActive();
  }

  onWheel(event: WheelEvent): void {
    if (!this.isWelcome) {
      this.mouseZoom$.next(event);
    }
  }

  ngOnInit(): void {
    this.truncateDescription();
    this.cd.markForCheck();
    this.mouseZoom$.pipe(
      // debounceTime(100), // TO-DO temporary disabled
      takeUntil(this.destroy$)
    ).subscribe((event) => {
      const deltaY = event.deltaY;
      const zoomSensitivity = 1;
  
      const currentZoom = this.mapService.map.getZoom();
      const newZoom = deltaY > 0 ? currentZoom - zoomSensitivity : currentZoom + zoomSensitivity;
      const mouseLatLng = this.mapService.map.mouseEventToLatLng(event);
      this.mapService.map.flyTo(mouseLatLng, newZoom, {
        animate: true,
        duration: 0.2  // Duration of the zoom animation in seconds
      });
      this.cd.markForCheck();
    })
  }

  ngAfterViewInit(): void {
    this.ready.emit();
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPointClick(): void {
    if (this.isWelcome) {
      this.close.emit();
    } else {
      this.pointClicked.emit(this.point);
    }
  }

  onClose(): void {
    this.close.emit();
  }

  onSignUp(): void {
    this.authService.redirectUrl = '/map';
    this.modalService.open(MODAL_COMPONENTS_NAME.RegisterModal);
    this.waitForUserChange();
  }

  onSignIn(): void {
    this.authService.redirectUrl = '/map';
    this.modalService.open(MODAL_COMPONENTS_NAME.LoginModal);
    this.waitForUserChange();
  }

  onGetFromAppStore(): void {
    window.open(this.APPLE_ID_URL, '_blank');
  }

  onGetFromGooglePlay(): void {
    window.open(this.ANDROID_ID_URL, '_blank');
  }

  private truncateDescription(): void {
    if (this.point?.poi?.text) {
      this.description = this.point.poi.text;
    }
  }

  private waitForUserChange(): void {
    this.authService.userChanged.pipe(
      skip(1),
      takeUntil(this.destroy$)
    ).subscribe(_ => {
      this.close.emit()
    })
  }
}
