export enum MODAL_COMPONENTS_NAME {
    LoginModal = 'LoginModal',
    RegisterModal = 'RegisterModal',
    PasswordResetRequestModal = 'PasswordResetRequestModal',
    PasswordResetModal = 'PasswordResetModal',
    ConfirmChangesModalModule = 'ConfirmChangesModalModule',
    ReportModalModule = 'ReportModalModule',
    LandingModal = 'LandingModal'
}

export const MODAL_MODULE_NAME_TO_REAL_MODULE_IMPORT_FN = {
    'LoginModal': () => import('../components/login-modal/login-modal.module').then(m => m.LoginModalModule),
    'RegisterModal': () => import('../components/register-modal/register-modal.module').then(m => m.RegisterModalModule),
    'PasswordResetRequestModal': () => import('../components/password-reset-request-modal/password-reset-request-modal.module').then(m => m.PasswordResetRequestModalModule),
    'PasswordResetModal': () => import('../components/password-reset-modal/password-reset-modal.module').then(m => m.PasswordResetModalModule),
    'ConfirmChangesModalModule': () => import('../components/confirm-changes-modal/confirm-changes-modal.module').then(m => m.ConfirmChangesModalModule),
    'ReportModalModule': () => import('../components/report-modal/report-modal.module').then(m => m.ReportModalModule),
    'LandingModal': () => import('../components/landing-modal/landing-modal.module').then(m => m.LandingModalModule),
}
