import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchType } from 'app_code/app/shared/interfaces/search-type.enum';
import { PointContentDTO } from 'app_code/app/shared/model/point-dto';

@Component({
  selector: 'shout-point-with-image',
  templateUrl: './point-with-image.component.html',
  styleUrls: ['./point-with-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointWithImageComponent {

  @Input() point: PointContentDTO;
  @Input() searchType: SearchType;
  @Input() hasSelectedItem: boolean;
  @Input() isCollectionHintVisible;
  @Input() image_path;

  @Output() clicked = new EventEmitter<void>();
  @Output() wheeled = new EventEmitter<WheelEvent>();

  SearchType = SearchType;

  onPointClick(): void {
    this.clicked.emit();
  }

  onWheel(ev: WheelEvent): void {
    this.wheeled.emit(ev);
  }
}
