<div class="point-on-map point-on-map--text" (click)="onPointClick()" (wheel)="onWheel($event)">
  <div class="point-on-map__text" [ngClass]="{'point-on-map--text--constant-size': isWelcome}">
    <div *ngIf="point?.poi?.likes > 0" class="point-on-map__text__likes">
      <shout-svg-icons [name]="'markerFilledHearth'" ></shout-svg-icons>
      <div class="point-on-map__text__likes__number">{{point.poi.likes > 99 ? '99+' : point.poi.likes}}</div>
    </div>

    <div *ngIf="point.poi.title" class="point-on-map__text__title" [attr.title]="point.poi.title">{{point.poi.title}}</div>
    <div *ngIf="description" class="point-on-map__text__description truncate-multiline" [attr.title]="point.poi.text" [innerHTML]="description | tagPipe | urlPipe | emailPipe">
    </div>
  </div>

  <div *ngIf="!isCollectionHintVisible && (searchType !== SearchType.COLLECTION || !hasSelectedItem)" class="point-on-map__collection-count">
    <span>{{point.numberOfPois > 99 ? '99+' : point.numberOfPois}}</span>
  </div>

  <div class="point-on-map__pointer">
    <div class="point-on-map__pointer__element">
        <shout-svg-icons [name]="'mapPointRectangle'"></shout-svg-icons> 
    </div>  
  </div>
</div>


