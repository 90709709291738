import { Subject, timer } from 'rxjs';
import { Log } from 'ng2-logger/browser';
import { AuthService } from './shared/services/auth/auth.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MixpanelService } from './shared/services/login/mixpanel.service';
import { map, take, filter, takeUntil, switchMap} from 'rxjs/operators';
import { Message } from '@stomp/stompjs';
import { Chat } from './shared/interfaces/chat';
import { ChatService } from './shared/services/chat/chat.service';
import { NotificationService } from './ui/notifications/services/notification.service';
import { RxStompService } from '@stomp/ng2-stompjs';
import { StoreDataService } from './shared/services/store/store-data.service';
import { AnimationOptions } from 'ngx-lottie';
import { NavigationEnd, Router } from '@angular/router';
import { INITIAL_DEFAULT_LOADING_TIME } from './shared/constants';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from './ui/modals/services/modals.service';
import { MODAL_COMPONENTS_NAME } from './ui/modals/services/modal-components-list';
import { LocalStorageService } from './shared/services/local-storage/local-storage.service';
import { LocalStorageKey } from './shared/model/local-storage-key';

@Component({
  selector: 'shout-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush // TO-DO - enable it after legacy profile and wall routes will be rewritten
})
export class AppComponent implements OnInit, OnDestroy {

  isReady = false;
  animationOptions: AnimationOptions = {
    path: 'assets/animations/sloader.json',
  };

  private log = Log.create('AppComponent');
  private destroy$ = new Subject<void>();

  constructor(private rxStompService: RxStompService,
    private chatService: ChatService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private mixpanel: MixpanelService,
    private storeDataService: StoreDataService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private modalsService: ModalsService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    document.documentElement.lang = this.translate.currentLang;
    this.mixpanel.init();
    this.authService.loginOnStartup()
    // TO-DO - Temporary disabling notification
    // this.subscribeToMsgQueue();
    this.hideLoaderWhenAppReady();
    this.showLadndingModalIfNeeded();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private subscribeToMsgQueue(): void {
    this.rxStompService.connected$.pipe(
      filter(value => value === 1),
      take(1),
      switchMap(_ => this.rxStompService.watch('/user/topic/chats').pipe(
        map((message: Message) => {
          this.log.data("message from WS chat: ", message);
          return <Chat>JSON.parse(message.body);
        }))),
      takeUntil(this.destroy$)
    ).subscribe(
      (chat: Chat) => {
        this.log.data("message from WS chat: ", chat);
        this.chatService.sendReceivedMessage(chat);
        this.notificationService.sendMessage(chat);
        this.cd.markForCheck();
      }
    )
  }

  private hideLoaderWhenAppReady(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd && !this.isReady),
      takeUntil(this.destroy$)
    ).subscribe((event: NavigationEnd) => {
      if (event.url === '/' || event.url.startsWith('/?')) { // Initial map loading, wait for all markers to be in place
        this.storeDataService.mapInitialMarkersLoadCompleted.pipe(
          takeUntil(this.destroy$)
        ).subscribe(_ => {
          this.isReady = true;
          this.cd.detectChanges();
        })
      } else { // default loader timeout for all other pages
        timer(INITIAL_DEFAULT_LOADING_TIME).pipe(
          take(1)
        ).subscribe(() => {
          this.isReady = true;
          this.cd.detectChanges();
        })
      }
    });
  }

  private showLadndingModalIfNeeded(): void {
    const wasBannerAlreadyShowed = this.localStorageService.getItem(LocalStorageKey.LANDING_MODAL_VISIBLE) ? true : false;
    if (!wasBannerAlreadyShowed && !this.authService.isAccountActive()) {
      this.modalsService.open(MODAL_COMPONENTS_NAME.LandingModal);
    }
  }
}
